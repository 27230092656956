import * as workerTimers from 'worker-timers';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import useTimer from 'easytimer-react-hook';

import { saveTimeSpentOnQuestion } from '../../store/actions/questionnaireDetailsActions';

const transformTo2NumbersAfterDecimal = (num) => Number((num / 1000).toFixed(2));

// const AVERAGE_DELAY_RATIO = 0.125;

export const useTimeSpentPerQuestion = ({ questionIndex, spentTime, isCountingBlocked, isMex }) => {
  const [timer] = useTimer();
  const dispatch = useDispatch();

  let time = spentTime ? Math.round(spentTime * 1000) : 0;
  const savingIntervalId = useRef(null);

  useEffect(() => {
    if (isCountingBlocked || isMex) {
      timer.stop();
      if (savingIntervalId.current !== null) {
        workerTimers.clearInterval(savingIntervalId.current);
        savingIntervalId.current = null
      }
      return
    };

    timer.start({
      precision: 'secondTenths',
      callback: () => {
        time += 100;
      },
    });

    savingIntervalId.current = workerTimers.setInterval(() => {
      dispatch(saveTimeSpentOnQuestion(questionIndex, transformTo2NumbersAfterDecimal(time)));
    }, 5000);

    return () => {
      timer.stop();

      if (savingIntervalId.current !== null) {
        workerTimers.clearInterval(savingIntervalId.current);
        savingIntervalId.current = null
      }
      dispatch(saveTimeSpentOnQuestion(questionIndex, transformTo2NumbersAfterDecimal(time)));
    };
  }, [questionIndex, isCountingBlocked, isMex]);

  // useEffect(() => {
  //   if (isCountingBlocked || isMex) {
  //     timer.stop();
  //     workerTimers.clearInterval(savingIntervalId.current);
  //   }
  // }, [isCountingBlocked, isMex]);
};
