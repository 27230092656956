import React, { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Terms } from './Terms/Terms';
import { Bio } from './Bio/Bio';
import { Spinner } from '../shared/Spinner';
import { WelcomeText } from './WelcomeText';
import { TechIssues } from './TechIssues/TechIssues';
import { Fairness } from './Fairness/Fairness';

import { managementApi } from '../../utils/agent';

const preTestReducer = (state, action) => {
  switch (action.type) {
    case 'PASS_WELCOME_TEXT':
      return { ...state, isWelcomeTextPassed: true };
    case 'ACCEPT_TERMS':
      return { ...state, isTermsAccepted: true };
    case 'ACCEPT_FAIRNESS_AGREEMENT':
      return { ...state, isFairnessAccepted: true };
    case 'BIO_EDITED':
      return { ...state, isBioEdited: true };
    case 'TECH_ISSUES':
      return { ...state, isTechIssuesPassed: true };
    default:
      return state;
  }
};

const PreTestComponent = ({ history, onComplete, is360, welcomeText, areTermsSkipped }) => {
  const DEFAULT_COMPLETE_BIO_TEMPLATE = 0;

  const [bioTemplatesData, setBioTemplatesData] = useState([]);
  const userId = useSelector((state) => state.questionnaireDetails.details.userId);
  const isAdmisteredTest = useSelector((state) => state.questionnaireDetails.details.isAdministered) || false;
  const shouldSkipFairnessPage = useSelector((state) => state.questionnaireDetails.details.skipFairnessPage);  
  let selectedBiodataTemplateId = useSelector((state) => state.questionnaireDetails.details.biodataTemplateId);
  let selectedBiodataTemplate = bioTemplatesData.find((template) => template.id === selectedBiodataTemplateId);

  useEffect(() => {
    managementApi.fetchBiodataTemplates(userId)
      .then(({ data }) => {
        setBioTemplatesData(data)
      })
      .catch((e) => console.error(e));

    return () => { }
  }, [])

  if (bioTemplatesData.length === 0) {
    selectedBiodataTemplate = null;
    if (![0, -1].includes(selectedBiodataTemplateId)) {
      selectedBiodataTemplateId = DEFAULT_COMPLETE_BIO_TEMPLATE;
    }
  }

  const onCancelHandler = React.useCallback(() => {
    history.push(is360 ? '/Start360' : '/Start');
  }, [history, is360]);

  const [state, dispatch] = React.useReducer(preTestReducer, {
    isTermsAccepted: areTermsSkipped,
    isFairnessAccepted: shouldSkipFairnessPage ? true : areTermsSkipped || is360 || isAdmisteredTest,
    isBioEdited: areTermsSkipped || is360,
    isWelcomeTextPassed: !welcomeText,
    isTechIssuesPassed: areTermsSkipped || is360 || isAdmisteredTest,
  });

  React.useEffect(() => {
    const { isWelcomeTextPassed, isTermsAccepted, isBioEdited, isTechIssuesPassed = true, isFairnessAccepted } = state;
    if (isWelcomeTextPassed && isTermsAccepted && isBioEdited && isTechIssuesPassed && isFairnessAccepted) {
      onComplete();
    }
  }, [state, onComplete]);

  return React.useMemo(() => {
    switch (true) {
      case !state.isWelcomeTextPassed:
        return (
          <WelcomeText
            text={welcomeText}
            onNext={() => {
              dispatch({ type: 'PASS_WELCOME_TEXT' });
            }}
          />
        );
      case !state.isTermsAccepted:
        return (
          <Terms
            onCancel={onCancelHandler}
            onNext={() => {
              dispatch({ type: 'ACCEPT_TERMS' });
            }}
          />
        );
      case !state.isFairnessAccepted && !shouldSkipFairnessPage:
        return (
          <Fairness
            onCancel={onCancelHandler}
            onNext={() => {
              dispatch({ type: 'ACCEPT_FAIRNESS_AGREEMENT' });
            }}
          />
        );
      case !state.isBioEdited:
        return < Bio biodataTemplate={selectedBiodataTemplate} biodataTemplateId={selectedBiodataTemplateId} onBioEdited={() => dispatch({ type: 'BIO_EDITED' })} />;
      case !state.isTechIssuesPassed:
        return (
          <TechIssues
            is360={is360}
            onCancel={onCancelHandler}
            onNext={() => {
              dispatch({ type: 'TECH_ISSUES' });
            }}
          />
        );
      default:
        return <Spinner />;
    }
  }, [state.isWelcomeTextPassed, state.isTermsAccepted, state.isFairnessAccepted, state.isBioEdited, state.isTechIssuesPassed, welcomeText, onCancelHandler]);
};

const mapStateToProps = (state) => {
  const {
    questionnaireDetails: {
      details: { isAdministered, welcomeText },
    },
  } = state;

  return {
    isAdministered,
    welcomeText,
  };
};

export const PreTest = compose(withRouter, connect(mapStateToProps))(PreTestComponent);
