import React from 'react';
import styled, { css } from 'styled-components';
import bg from '../../templates/images/bg.jpg';

const StyledPage = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 320px;
  width: 100%;
  padding-bottom: ${({ theme }) => theme.footer.height};

  div:focus-visible {
    outline: none;
  }

  @media (max-height: 414px) and (max-width:800px){
    padding-bottom: 0px;
  }
  
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    position: relative;
    padding-bottom: 0;
    margin-top: 0;
  }

  ${(props) =>
    props.withBackground &&
    css`
      @media (min-width: ${({ theme }) => theme.viewports.xs}) {
      &:before {
          background-image: url("${bg}");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          top: 0;
          left: 0; //for IE;
          position: fixed;
          content: ' ';
          width: 100%;
          height:100vh; // fix for mobile browser address bar appearing disappearing
          overflow:hidden;
        }
      }
      padding: 20px 0;
      @media (min-width: ${({ theme }) => theme.viewports.sm}) {
        padding: 0;
      }
    `}
  
  ${(props) =>
    props.withBackgroundMobile &&
    css`
    padding: 20px 0;
    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      padding: 0;
    }
    
    &:before {
        background-image: url("${bg}");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        top: 0;
        left: 0; // for IE  
        position: fixed;
        content: ' ';
        width: 100%;
        height:100vh; // fix for mobile browser address bar appearing disappearing
        overflow:hidden;
      }
    `}  

  ${(props) =>
    props.withOpacity &&
    css`
      &:after {
        position: fixed;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 120%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1;
      }
    `}
  
  ${(props) =>
    props.withBlur &&
    css`
      &:after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 120%;
        -webkit-backdrop-filter: blur(15px); /* apply the blur */
        backdrop-filter: blur(15px); /* apply the blur */
        pointer-events: none; /* make the pseudo class click-through */
      }
    `}
  
  ${(props) =>
    props.withBlurOpacity &&
    css`
      &:after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 120%;
        background: rgba(0, 0, 0, 0.5);
        -webkit-backdrop-filter: blur(15px); /* apply the blur */
        backdrop-filter: blur(15px); /* apply the blur */
        pointer-events: none; /* make the pseudo class click-through */
      }
    `}
`;

const ModalStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 5%;
  max-width: 350px;
  position: relative;
  font-size: 1.6rem;

  @media (min-width: ${({ theme }) => theme.viewports.xs}) {
    max-width: 376px;
    padding: 0 58px;
    width: 100%;
    min-height: 526px;
    height: auto; //for Chrome
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0 24px 44px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }
  @media (max-height: 414px) and (max-width: 818px) {
    max-width: 95%;
    height: auto;
    min-height: auto;
    padding: 50px 100px !important;
    margin-top: -20px;
  }

  form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const MainStyled = styled.div`
  padding-top: ${({ theme }) => theme.footer.height};
  padding-bottom: ${({ theme }) => theme.footer.height};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    height: auto;
    padding-top: 0;

    flex-grow: 1;
    justify-content: center;
  }
  @media (max-height: 414px) and (max-width: 818px) {
    padding-bottom: 35px;
    padding-top: 35px;
  }
  @media (min-width: ${({ theme }) => theme.viewports.sm}) and (max-height: 414px) {
    padding-bottom: 35px;
  }
`;

const TitleStyled = styled.h1`
  color: ${({ theme }) => theme.colors.medGray};
  font-weight: bold;
  letter-spacing: -0.67px;
  line-height: 32px;
  text-align: center;
  font-size: 24px;
  padding: 0 10%;
  margin-bottom: 0;
  max-width: 100%;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    line-height: 32px;
    padding: 0;
    font-size: 24px;
  }
`;

export const Main = ({ children, className }) => {
  return <MainStyled className={className}>{children}</MainStyled>;
};

export const ModalBox = ({ children, className }) => {
  return <ModalStyled className={className}>{children}</ModalStyled>;
};

export const Page = ({
  children,
  className,
  withBackground,
  withOpacity,
  withBlur,
  withBackgroundMobile,
  withBlurOpacity,
  ...props
}) => {
  return (
    <StyledPage
      withBlur={withBlur}
      className={className}
      withOpacity={withOpacity}
      withBackground={withBackground}
      withBackgroundMobile={withBackgroundMobile}
      withBlurOpacity={withBlurOpacity}
      {...props}
    >
      {children}
    </StyledPage>
  );
};

export const Title = ({ children, className }) => {
  return <TitleStyled className={className}>{children}</TitleStyled>;
};
