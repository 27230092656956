import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import useKey from "@rooks/use-key";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ButtonTypes, ButtonVariants } from "../../shared/Button";
import { LogoStyled } from "../Welcome/Welcome.styled";
import {
    ButtonStyled,
    FooterStyled,
    StyledPage,
    ButtonsHolderStyled,
    TextStyled,
    InfoFieldStyled
} from "./TechIssues.styled";
import { setRespondentData } from "../../../store/actions/questionnaireDetailsActions";
import { ModalBox } from "../../shared/layout";
import { Spinner } from "../../shared/Spinner";
import { assessmentApi } from "../../../utils/agent";

export const TechIssues = ({ onNext, is360 }) => {
    const buttonRefNext = useRef();
    const params = useParams();
    const dispatch = useDispatch();
    const [isError, setError] = useState(false);
    const respondent = useSelector((state) => state.questionnaireDetails.respondent);

    useEffect(() => {
        const focusOnButton = () => {
            try {
                buttonRefNext.current.focus();
                // eslint-disable-next-line no-empty
            } catch { }
        };
        if (!respondent) {
            const { accountWithInvitation, password } = params;
            assessmentApi[is360 ? 'login360' : 'login'](accountWithInvitation, password)
                .then(({ data }) => {
                    if (typeof data === 'object') {
                        dispatch(setRespondentData(is360 ? data.qInvitation : data));
                    } else {
                        setError(true);
                    }
                })
                .catch(() => {
                    setError(true);
                });
        } else {
            focusOnButton();
        }
    }, [respondent, params, is360, dispatch]);

    const { t } = useTranslation();

    useKey(["Enter"], () => {
        onNext();
    });

    React.useEffect(() => {
        try {
            window.scroll({
                top: 0,
                left: 0,
            });
        } catch (error) {
            // just a fallback for older browsers
            window.scrollTo(0, 0);
        }
    }, []);

    if (!respondent && !isError) return <Spinner />;

    return (
        <StyledPage withBackgroundMobile withBackground>
            <ModalBox>
                <LogoStyled />
                <TextStyled>{t('techDifficulties')}</TextStyled>
                <InfoFieldStyled>
                    <span>
                        {t('contactifproblems')}&nbsp;
                        <a href={`mailto:${respondent.userName}`}>{respondent.userName}</a>
                    </span>
                </InfoFieldStyled>
            </ModalBox>
            <FooterStyled>
                <ButtonsHolderStyled>
                    <ButtonStyled
                        variant={ButtonVariants.PRIMARY}
                        type={ButtonTypes.BUTTON}
                        onClick={onNext}
                    >
                        {t("continue")}
                    </ButtonStyled>
                </ButtonsHolderStyled>
            </FooterStyled>
        </StyledPage>
    );
};

