import React, { useRef } from 'react'
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const CT_URL_PREFIX = 'https://media.genesysonline.net/gs2020container/Checking';

 const StyledVideo = styled.div`
    //  display: none;
 `;

const SVGPrealoaderItem = ({ svgCode }) => {

    if (svgCode === undefined) return;

    const SVGRef = useRef()
    return (
        <link
            rel='preload'
            ref={SVGRef}
            as='image'
            type='image/svg+xml'
            href={`${CT_URL_PREFIX}/${svgCode}.svg`}
        /> 
    );
 };

export const SVGPrealoader = () => {
    const { svgUrls } = useSelector((state) => state.testDetails.config);
     
     const imageRefs = svgUrls.map((svgGroup) => Object.values(svgGroup));
     const imageRefs2 = Array.prototype.concat.apply([], imageRefs);
        
     return (
         <StyledVideo>
             {imageRefs2.map((value) => {
                 return (
                     <SVGPrealoaderItem key={value+Math.random()} svgCode={value} />
                 );
             })}
         </StyledVideo>
     )
 };