/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'
import styled from 'styled-components';

const StyledVideo = styled.div`
    display: none;
`;

export const VideoPrealoader = ({ videos }) => {

    return (
        <StyledVideo>
            {videos.map((videoItem) => {
                return (
                    <link key={videoItem} rel='preload' href={videoItem} as='video' type='video/mp4' />
                );
            })}
        </StyledVideo>
    )
};
