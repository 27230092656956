import React from 'react';
import styled from 'styled-components';
import { FeedbackStyled, FullHeight, QuestionTextStyled } from './shared/components';
import { CardsList } from '../shared/CardsList';

const StyledTableContainer = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.viewports.sm}) and (max-height: 700px) {
    height: 100%;
    padding-top: 20px;
  }

  .container {
    padding: 0;
    width: 100%;
  }

  .half-height {
    @media (min-width: ${({ theme }) => theme.viewports.md}) {
      height: 115px;
    }
  }
`;

const QuestionWichTextStyled = styled(QuestionTextStyled)`
  margin-bottom: 0;
  font-size: 24px;
  line-height: 1.4;

  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    font-size: 24px;
    line-height: 1.4;
  }

  @media (max-height: ${({ theme }) => theme.viewports.presumablyDesktopHeight}) {
    padding-bottom: 0;
    padding-top: 10px;
    font-size: 20px;
  }
  @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
      theme.viewports.mdx}) and (orientation: portrait) {
    padding-bottom: 0;
    padding-top: 0;
    font-size: 18px;
    margin-top: 25px;
  }
`;

const StyledTable = styled.div`
  color: #202629 !important;
  overflow: auto;

  h3,
  p {
    margin-top: 0 !important;
    margin-bottom: 10px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-align: center;

    @media (min-width: ${({ theme }) => theme.viewports.sm}) {
      font-size: 18px !important;
    }
  }

  & + table:not(.custom-table) {
    margin-bottom: 10px;
  }

  table:not(.custom-table) {
    border: 1px solid #688695 !important;
    width: calc(100% - 1px);
    border-collapse: collapse;

    thead,
    thead tr {
      background-color: #e7f6fc !important;
    }

    caption {
      margin-bottom: 10px;
      font-weight: 500 !important;
      font-size: 14px !important;
      @media (min-width: ${({ theme }) => theme.viewports.sm}) {
        font-size: 18px !important;
      }
    }

    tr:nth-child(even) {
      background-color: #f4f4f4;
    }

    th,
    td {
      border: 1px solid #688695 !important;
      padding: 2px !important;
      font-size: 12px !important;

      @media (min-height: 600px) {
        font-size: calc(3.3333333333vh - 8px) !important;
        //padding: calc(1.6666666667vh - 8px) !important;
      }

      @media (min-height: 720px) {
        font-size: 16px !important;
        //padding: 4px !important;
      }

      @media (min-width: 768px) {
        font-size: calc(1.1594202899vw + 3.0956521739px) !important;
      }

      @media (min-width: 1113px) {
        font-size: ${(props) => (props.smallerFontSize ? '14px' : '16px')} !important;
      }

      @media (max-height: ${({ theme }) => theme.viewports.presumablyDesktopHeight}) {
        font-size: 10px !important;
      }

      @media only screen and (min-width: ${({ theme }) => theme.viewports.sm}) and (max-device-width: ${({ theme }) =>
          theme.viewports.mdx}) and (orientation: portrait) {
        font-size: 11px !important;
      }
    }

    td > div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  table#crtbin-table3 thead > tr,
  table#crtbin-table3 tr:last-child,
  table#crtbin-table3 th {
    border: 1px solid #688695 !important;
  }
`;

const StyledFullHeight = styled(FullHeight)`
  @media (min-width: ${({ theme }) => theme.viewports.sm}) {
    padding-top: 70px;
  }
`;

export const TableItem = ({
  values: { mainitem, responses, table, feedback },
  selectedResponse,
  onResponse,
  config,
  isBlockModalVisible,
  isHelpVisible,
  isSidePanelOpen,
  testId,
}) => {
  return (
    <>
      <StyledFullHeight>
        <StyledTableContainer>
          <StyledTable
            smallerFontSize={testId === 'CRTBIN'}
            dangerouslySetInnerHTML={{
              __html: table,
            }}
          />
          <QuestionWichTextStyled
            dangerouslySetInnerHTML={{
              __html: mainitem,
            }}
          />
          {!!feedback && (
            <FeedbackStyled
              dangerouslySetInnerHTML={{
                __html: feedback,
              }}
            />
          )}
        </StyledTableContainer>
      </StyledFullHeight>
      <CardsList
        isAbstract={config.isAbstract}
        className="for-tables"
        items={responses}
        selectedResponse={selectedResponse}
        onResponse={onResponse}
        config={config}
        isBlockModalVisible={isBlockModalVisible}
        isHelpVisible={isHelpVisible}
        isSidePanelOpen={isSidePanelOpen}
      />
    </>
  );
};
