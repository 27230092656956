import * as workerTimers from "worker-timers";
import { useCallback, useEffect, useState, useRef } from "react";

export const useTimer = ({
  initialTime = 0,
  interval = 1000,
  step = 1,
  timerType = "INCREMENTAL",
  endTime,
  onTimeRunOut,
} = {}) => {
  const [time, setTime] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);
  const intervalIdRef = useRef(null);

  const reset = useCallback(() => {
    setIsRunning(false);
    setTime(0);
  }, []);

  const start = useCallback(() => {
    setIsRunning(true);
  }, []);

  const pause = useCallback(() => {
    setIsRunning(false);
  }, []);

  useEffect(() => {
    // let intervalId = null;

    if (isRunning) {
      intervalIdRef.current = workerTimers.setInterval(() => {
        setTime((previousTime) => {
          const newTime =
            timerType === "INCREMENTAL"
              ? previousTime + step
              : previousTime - step;

          return newTime;
        });
      }, interval);
    } else if (intervalIdRef.current) {
      workerTimers.clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
    return () => {
      if (intervalIdRef.current) {
        workerTimers.clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [isRunning, step, timerType, interval]);

  useEffect(() => {
    if (isRunning && time === endTime) {
      reset();
      onTimeRunOut();
    }
  }, [endTime, time, isRunning, reset, onTimeRunOut]);

  return { isRunning, pause, reset, start, time };
};
