import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import * as workerTimers from "worker-timers";
import { saveSessions } from "../../store/actions/questionnaireDetailsActions";

export function useSaveSessionTimer({
  isUserReturnScreenVisible,
  isResultSubmitted,
  is360,
  timeout = 10000,
}) {
  const sessionTimerId = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isUserReturnScreenVisible && !isResultSubmitted) {
      sessionTimerId.current = workerTimers.setInterval(() => {
        dispatch(saveSessions(is360));
      }, timeout);
    }

    return () => {
      if (sessionTimerId.current !== null) {
        workerTimers.clearInterval(sessionTimerId.current);
        sessionTimerId.current = null;
      }
    };
  }, [isUserReturnScreenVisible, isResultSubmitted]);

  const clearSaveSessionTimer = useCallback(() => {
    if (sessionTimerId.current !== null) {
      workerTimers.clearInterval(sessionTimerId.current);
      sessionTimerId.current = null;
    }
  }, [sessionTimerId]);

  return { clearSaveSessionTimer };
}
